body {
    background-color: hsl(0, 0%, 7%); 
    color: hsl(0, 0%, 100%); 
    font-family: 'Arial', sans-serif; 
    padding: 25px;
  }

#global-div {
    background-color: hsl(0, 0%, 10%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 8px hsla(219, 81%, 60%, 0.7),
                0 0 20px hsla(219, 81%, 65%, 0.5),
                0 0 35px hsla(219, 81%, 70%, 0.3);
}

.global-header{
    font-size: 2.5rem;
    background-color: hsl(0, 0%, 7%);
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

    

  