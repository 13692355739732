header, nav {
    margin: none;
    padding: 20px;
    box-sizing: border-box;
}

#nav-items{
    list-style-type: none; 
    margin: 0;
    padding: 0;
    display: flex; 
    justify-content: center; 
    align-items: center; 
}

nav li {
    margin: 0 15px; 
}

nav a {
    text-decoration: none; 
    color: white; 
    font-weight: bold; 
}

.active {
    border-radius: 8px;
    box-shadow: 0 0 8px hsla(219, 81%, 60%, 0.7),
                0 0 20px hsla(219, 81%, 65%, 0.5),
                0 0 35px hsla(219, 81%, 70%, 0.3);
            
    color: white;
    background-color: hsla(219, 81%, 65%, 0.5);
    font-weight: bold;
}

      