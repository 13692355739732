#about-me{
    padding: 20px;
}

.skills-container, .ref-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }

 
  
  .skill, .ref {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: hsl(0, 0%, 0%);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 8px hsla(219, 81%, 60%, 0.7),
                0 0 20px hsla(219, 81%, 65%, 0.5),
                0 0 35px hsla(219, 81%, 70%, 0.3);              
  }
 
  