.contact-container {
    display: inline-flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  
#global-div h1, #global-div h3 {
    margin: 0;
    padding: 0;
}

#global-div h3 {
    margin-top: 10px;
    margin-bottom: 50px;
    font-style: italic;
}
  button, p {
    margin-left: 5px;
  }
  .contact-button{
    border-radius: 5px;
    box-shadow: 0 0 8px hsla(219, 81%, 60%, 0.7),
                0 0 20px hsla(219, 81%, 65%, 0.5),
                0 0 35px hsla(219, 81%, 70%, 0.3);
  }
  .contact-button:hover {
    cursor: pointer;
  }
  